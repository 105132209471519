import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider, Box, Grid, Typography, Modal } from "@mui/material";
import {
  Add as AddIcon,
  TrendingUp as TrendingUpIcon,
  AccessTime as AccessTimeIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
} from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";

import { GetStats } from "../../services/user.service";
import { GetProjects } from "../../services/project.service";
import { theme, ColorButton, modalStyle } from "../../theme";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import CreateProjectFlow from "../../components/CreateProject/CreateProjectFlow";
import ImageUploadModal from "../../components/ImageUploadModal/ImageUploadModal";
import Project from "../Project/Project";
import CloseConfirmationModal from "../../components/Modals/CloseConfirmationModal";
import ProjectList from "./Components/ProjectList";

const Dashboard = ({ userData, accessToken, setProjectID, setSideBarSelected }) => {
  const { t } = useTranslation();
  const { user, isLoading: authLoading } = useAuth0();
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState({ stats: null, projects: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [showCalcExplanationPopUp, setShowCalcExplanationPopUp] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const fetchData = useCallback(async () => {
    if (!accessToken || !user) return;

    try {
      const [statsData, projectsData] = await Promise.all([
        GetStats(accessToken, user.sub.split("|")[1]),
        GetProjects(accessToken, user.sub.split("|")[1])
      ]);

      setDashboardData({
        stats: statsData.data,
        projects: projectsData.data
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessageType("error");
      setMessage("Error getting projects and stats");
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, user]);

  useEffect(() => {
    if (!authLoading) {
      fetchData();
      setSideBarSelected(0);
    }
  }, [authLoading, fetchData, setSideBarSelected]);

  const handleUploadImages = () => setOpenImageUploadModal(true);

  const DashboardHeader = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("dashboard.pageTitle")}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {t("dashboard.welcome", { name: userData?.name || user?.name })}
          </Typography>
        </Box>
        <ColorButton
          startIcon={<AddIcon />}
          onClick={handleUploadImages}
          component={motion.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {t("dashboard.uploadImages")}
        </ColorButton>
      </Box>
    </motion.div>
  );

  const StatCard = ({ title, value, icon, showPopup, onMouseEnter, onMouseLeave }) => (
    <Box
      sx={{
        bgcolor: 'white',
        borderRadius: 2,
        p: 3,
        boxShadow: 1,
        position: 'relative',
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {icon}
        </Box>
      </Box>
      <Typography variant="h4">{value}</Typography>
      <AnimatePresence>
        {showPopup && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: 'grey.300',
              borderRadius: '4px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              padding: '8px',
              zIndex: 1000,
              maxWidth: '300px',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {title}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );

  if (isLoading || authLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <LoadingScreen
          sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
          header={t("dashboard.loading")}
          text={t("dashboard.loadingText")}
        />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        {openProject ? (
          <Project />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <DashboardHeader />
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={4}>
                <StatCard
                  title={t("dashboard.imagesProcessed")}
                  value={dashboardData.stats?.images_processed}
                  icon={<TrendingUpIcon color="primary" />}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StatCard
                  title={t("dashboard.timeSaved")}
                  value={dashboardData.stats?.time_saved}
                  icon={<AccessTimeIcon color="primary" />}
                  showPopup={showCalcExplanationPopUp}
                  onMouseEnter={() => setShowCalcExplanationPopUp(true)}
                  onMouseLeave={() => setShowCalcExplanationPopUp(false)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StatCard
                  title={t("dashboard.projectsInitiated")}
                  value={dashboardData.projects.length}
                  icon={<PeopleAltOutlinedIcon color="primary" />}
                />
              </Grid>
            </Grid>
            <ProjectList 
              projects={dashboardData.projects}
              setProjectID={setProjectID}
              navigate={navigate}
              setSideBarSelected={setSideBarSelected}
            />
          </motion.div>
        )}
        <Modal open={openCreateProject} onClose={() => setOpenCreateProject(false)}>
          <Box sx={modalStyle}>
            <CreateProjectFlow
              setOpenCreateProject={setOpenCreateProject}
              accessToken={accessToken}
              userID={user.sub.split("|")[1]}
              createTrialAfterProject={true}
            />
          </Box>
        </Modal>
        <CloseConfirmationModal
          openConfirmationModal={openConfirmationModal}
          setOpenConfirmationModal={setOpenConfirmationModal}
          setOpenImageUploadModal={setOpenImageUploadModal}
        />
        <Modal
          open={openImageUploadModal}
          onClose={() => setOpenConfirmationModal(true)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <ImageUploadModal
            setOpenImageModal={setOpenImageUploadModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            accessToken={accessToken}
            userID={userData._id}
            nameOfUser={user.name}
          />
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;