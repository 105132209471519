// React
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AuthenticationGuard } from "./Authentication/authentication-guard";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
// Redirects
import Login from "./Authentication/Login";
import Logout from "./Authentication/Logout";
// Page imports
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import Dashboard from "./pages/Dashboard/Dashboard.js";
import Contact from "./pages/Contact/Contact.js";
import Pricing from "./pages/Pricing/Pricing.js";
import Products from "./pages/Products/Products.js";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail.js";
import Project from "./pages/Project/Project";
// Component imports
import NavBar from "./components/TopNavBar/NavBar";
import Footer from "./components/Footer/footer.jsx";
// Services
import { GetUserByID } from "./services/user.service";
// CSS
import "./App.css";
import "./assets/fonts/stylesheet.css";
// Material UI
import { Box, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
// Animations
import { AnimatePresence } from "framer-motion";

// Google Tag Manager Initialization
const gtmID = process.env.REACT_APP_GTM_ID;
const environment = process.env.REACT_APP_ENV;
if (environment === "production") {
  TagManager.initialize({ gtmId: gtmID });
}

function App() {
  //----------Hooks----------//
  const { t } = useTranslation();
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const location = useLocation();

  //----------States----------//
  const [token, setToken] = React.useState(null);
  const [userData, setUserData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [projectDeleted, setProjectDeleted] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [sideBarSelected, setSideBarSelected] = useState(null);

  //----------Use Effects----------//
  // Get user data on page load
  useEffect(() => {
    if (!user) {
      setIsLoading(false);
      return;
    }

    const fetchUser = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
        try {
          const { data } = await GetUserByID(
            token,
            user.sub.split("|")[1],
            user.email
          );

          if (data) {
            setUserData(data);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchUser();
  }, [user, getAccessTokenSilently]);

  // Define common Box style
  const appBoxStyles = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    maxWidth: "100vw",
    backgroundColor: theme.palette.background.default,
    fontFamily: theme.typography.fontFamily,
  };

  // Render
  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            ...appBoxStyles,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={appBoxStyles}>
        <AnimatePresence>
          {/*----------Header Area----------*/}
          <NavBar userData={userData} InfoUpdated={userData.InfoUpdated} />
          {/*----------Main Content Area----------*/}
          <Box
            sx={{
              flexGrow: 1,
              minHeight: "100vh",
              width: "100%",
              marginTop: { xs: "64px", sm: "100px", md: "64px" },
              backgroundColor: theme.palette.background.default,
            }}
          >
            {user && user.email_verified === false ? (
              <>
                <VerifyEmail />
                <Routes>
                  <Route path="/logout" element={<Logout />} />
                </Routes>
              </>
            ) : (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route
                  path="/profile"
                  element={
                    <AuthenticationGuard
                      component={Profile}
                      accessToken={token}
                      user={user}
                    />
                  }
                />
                <Route
                  path="/project/:projectID"
                  element={
                    <AuthenticationGuard
                      component={Project}
                      accessToken={token}
                      user={user}
                      setProjectDeleted={setProjectDeleted}
                    />
                  }
                />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/login" element={<Login />} />
                <Route path="/Logout" element={<Logout />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/products" element={<Products />} />
                <Route
                  path="/dashboard"
                  element={
                    <AuthenticationGuard
                      component={Dashboard}
                      userData={userData}
                      user={user}
                      accessToken={token}
                      setProjectID={setProjectID}
                      setSideBarSelected={setSideBarSelected}
                    />
                  }
                />
              </Routes>
            )}
          </Box>
        </AnimatePresence>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
