import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    List, ListItem, ListItemButton, ListItemIcon,
    Modal, Box, Typography, Button, Divider,
    List as MuiList, ListItemText, IconButton, Avatar, Badge
} from "@mui/material";
import {
    SpaceDashboard as SpaceDashboardIcon,
    LocalLibrary as LocalLibraryIcon,
    Inbox as InboxIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Delete as DeleteIcon,
    AccountCircle as AccountCircleIcon,
    Logout as LogoutIcon,
    Home as HomeIcon,
    ShoppingCart as ShoppingCartIcon,
    AttachMoney as AttachMoneyIcon,
    ContactMail as ContactMailIcon
} from "@mui/icons-material";
import DeleteProjectModal from "../../pages/Project/Components/DeleteProjectModal/DeleteProjectModal";
import CreateProjectFlow from "../CreateProject/CreateProjectFlow";
import { GetProjects, GetNotifications, SendInviteResponse } from "../../services/project.service";
import "./dashboard-sidebar.css";
import { useAuth0 } from "@auth0/auth0-react";

const DashboardSidebar = ({
    userData,
    projectDeleted,
    handleClose,
    isAuthenticated,
    hasNewNotification,
    setHasNewNotification
}) => {
    const [projectsDropDownOpen, setProjectsDropDownOpen] = useState(true);
    const [notifsDropDownOpen, setNotifsDropDownOpen] = useState(false);
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [projects, setProjects] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [sideBarSelected, setSideBarSelected] = useState(0);
    // get token from auth0
    const { user, getAccessTokenSilently: getAccessToken } = useAuth0();
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            getAccessToken().then(token => setToken(token));
        }
    }, [isAuthenticated]);

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const authenticatedItems = [
        { text: t("sidebar.dashboard"), icon: <SpaceDashboardIcon />, index: 1, href: "/dashboard" },
        { text: t("sidebar.projects"), icon: <LocalLibraryIcon />, index: 2 },
        { text: t("sidebar.notifications"), icon: 
            <Badge color="error" variant="dot" invisible={!hasNewNotification}>
                <InboxIcon />
            </Badge>, 
        index: 3 },
        { text: t("navigation.logout"), icon: <LogoutIcon />, index: 4, href: "/logout" }
    ];

    const unauthenticatedItems = [
        { text: t("navigation.home"), icon: <HomeIcon />, index: 0, href: "/" },
        { text: t("navigation.products"), icon: <ShoppingCartIcon />, index: 1, href: "/products" },
        { text: t("navigation.pricing"), icon: <AttachMoneyIcon />, index: 2, href: "/pricing" },
        { text: t("navigation.contact"), icon: <ContactMailIcon />, index: 3, href: "/contact" },
        { text: t("navigation.login"), icon: <AccountCircleIcon />, index: 4, href: "/login" }
    ];

    const sidebarItems = isAuthenticated ? authenticatedItems : unauthenticatedItems;

    useEffect(() => {
        if (isAuthenticated && token && userData._id) {
            fetchNotifications();
            fetchProjects();
        }
    }, [isAuthenticated, token, userData._id, projectDeleted]);

    useEffect(() => {
        if (isAuthenticated && location.pathname.startsWith("/project/") && !projectsDropDownOpen) {
            setProjectsDropDownOpen(true);
        }
        // Set the selected sidebar item based on the current route
        const currentItem = sidebarItems.find(item => item.href === location.pathname);
        if (currentItem) {
            setSideBarSelected(currentItem.index);
        }
    }, [location.pathname, isAuthenticated]);

    const fetchNotifications = async () => {
        const response = await GetNotifications(token, userData.email);
        if (response.data && response.data.length > 0) {
            const formattedNotifications = response.data.map(notification => ({
                project_id: notification.project_id,
                project_name: notification.project_name,
                message: {
                    prefix: "You have been invited to join the project: ",
                    highlight: notification.project_name
                },
                notification_type: "invite"
            }));
            setNotifications(formattedNotifications);
            setNotifsDropDownOpen(true);
            setHasNewNotification(true);
        }
    };

    const NotificationItem = ({ notification }) => {
        return (
            <Typography>
                {notification.message.prefix}
                <strong>{notification.message.highlight}</strong>
            </Typography>
        );
    };


    const fetchProjects = async () => {
        try {
            const response = await GetProjects(token, userData._id);
            setProjects(response.data);
        } catch (error) {
            console.error("Error fetching projects: ", error);
        }
    };

    const handleSidebarClick = (index, href) => {
        if (href) {
            navigate(href);
            setSideBarSelected(index);
            handleClose();
        } else if (isAuthenticated) {
            toggleDropdown(index);
        }
    };

    const toggleDropdown = (index) => {
        if (index === 2) {
            setProjectsDropDownOpen(!projectsDropDownOpen);
            setNotifsDropDownOpen(false);
        } else if (index === 3) {
            setNotifsDropDownOpen(!notifsDropDownOpen);
            setProjectsDropDownOpen(false);
        }
    };

    const openProject = (projectID) => {
        navigate(`/project/${projectID}`);
        setSideBarSelected(null);
        handleClose();
    };

    const handleInvite = async (accepted, projectID) => {
        const formData = new FormData();
        formData.append("email", userData.email);
        formData.append("project_id", projectID);
        formData.append("user_id", userData._id);
        formData.append("response", accepted);

        try {
            await SendInviteResponse(token, formData);
            if (accepted) {
                await fetchProjects();
                openProject(projectID);
                
            }
            setNotifications(prev => prev.filter(n => n.project_id !== projectID));
        } catch (error) {
            console.error("Error responding to invite: ", error);
        }
    };

    const renderDropdownContent = (item) => {
        if (!isAuthenticated) return null;

        if (item.text === t("sidebar.projects") && projectsDropDownOpen) {
            return (
                <Box sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            setOpenCreateProject(true);
                            handleClose();
                        }}
                        sx={{
                            mb: 2,
                            backgroundColor: '#20B3CE',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#1C9FB6',
                            },
                        }}
                    >
                        {t("sidebar.create")}
                    </Button>
                    <MuiList>
                        {projects && projects.map((project) => (
                            <ListItem
                                key={project.id}
                                disablePadding
                                secondaryAction={
                                    userData === project.project_creator && (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                setOpenDeleteProjectModal(true);
                                                setSelectedProject(project);
                                            }}
                                            sx={{ color: '#c4c4c4', '&:hover': { color: 'red' } }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )
                                }
                            >
                                <ListItemButton
                                    onClick={() => openProject(project._id)}
                                    selected={location.pathname === `/project/${project._id}`}
                                >
                                    <ListItemText primary={project.project_name} />
                                    <Box
                                        sx={{
                                            bgcolor: project.project_color || 'transparent',
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            ml: 1
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </MuiList>
                </Box>
            );
   } else if (item.text === t("sidebar.notifications") && notifsDropDownOpen) {
            return (
                <Box sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}>
                    <MuiList>
                        {notifications.length === 0 ? (
                            <ListItem>
                                <ListItemText
                                    primary={t("sidebar.noNotifications")}
                                    primaryTypographyProps={{ color: 'grey' }}
                                />
                            </ListItem>
                        ) : (
                            notifications.map((notification, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={<NotificationItem notification={notification} />}
                                        secondary={
                                            <Box sx={{ mt: 1 }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleInvite(true, notification.project_id)}
                                                    sx={{ mr: 1 }}
                                                >
                                                    {t("sidebar.acceptInvite")}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => handleInvite(false, notification.project_id)}
                                                >
                                                    {t("sidebar.declineInvite")}
                                                </Button>
                                            </Box>
                                        }
                                    />  
                                </ListItem>
                            ))
                        )}
                    </MuiList>
                </Box>
            );
        }
    };

    return (
        <Box sx={{ width: "100%", height: "100%", bgcolor: "background.paper" }}>
            {/* profile and close button */}
            {isAuthenticated && (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2, width: "100%" }} 
                    onClick={() => {
                        navigate("/profile");
                        handleClose();
                    }}
                    onHover={{ cursor: 'pointer' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            width: '100%',
                            cursor: 'pointer',
                            "&:hover": {
                                bgcolor: "#E2F5F9"
                            }
                        }}

                    >
                        <Avatar
                            src={user.picture}
                            alt={userData.name}
                            sx={{
                                width: 40,
                                height: 40,
                                mr: 1
                            }}
                        />
                        <Typography variant="subtitle2" sx={{ fontWeight: 'small', fontSize: '1.2rem', width: '80%', textOverflow: 'ellipsis', maxWidth: '80%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {userData.name ? userData.name : "Unknown/Inconnu"}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Divider />
            <List
                sx={{
                    mt: 3,
                    width: "100%",
                    fontFamily: "Avenir Next Cyr",
                    '& .Mui-selected, & .Mui-selected:hover': {
                        bgcolor: "#E2F5F9",
                        '&, & .MuiListItemIcon-root': {
                            color: "#20B3CE",
                        },
                    },
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: "#E2F5F9",
                        '&, & .MuiListItemIcon-root': {
                            color: "#23C9E8",
                        },
                    },
                }}
                disablePadding
            >
                {sidebarItems.map((item) => (
                    <React.Fragment key={item.index}>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => handleSidebarClick(item.index, item.href)}
                                selected={sideBarSelected === item.index || (item.href && location.pathname.startsWith(item.href))}
                            >
                                <ListItemIcon sx={{
                                    color: (sideBarSelected === item.index || (item.href && location.pathname.startsWith(item.href))) ? "#20B3CE" : "inherit"
                                }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                                {item.index === 2 && isAuthenticated && (projectsDropDownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
                                {item.index === 3 && isAuthenticated && (notifsDropDownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
                            </ListItemButton>
                        </ListItem>
                        {renderDropdownContent(item)}
                    </React.Fragment>
                ))}
            </List>
            {isAuthenticated && openCreateProject && (
                <Modal open={openCreateProject} onClose={() => setOpenCreateProject(false)}>
                    <CreateProjectFlow
                        setOpenCreateProject={setOpenCreateProject}
                        accessToken={token}
                        userID={userData._id}
                        setProjects={setProjects}
                        projects={projects}
                    />
                </Modal>
            )}
            {isAuthenticated && openDeleteProjectModal && selectedProject && (
                <DeleteProjectModal
                    openDeleteProjectModal={openDeleteProjectModal}
                    setOpenDeleteProjectModal={setOpenDeleteProjectModal}
                    projectID={selectedProject._id}
                    projectTitle={selectedProject.project_name}
                    accessToken={token}
                    setProjectDeleted={() => {
                        setProjects(prev => prev.filter(proj => proj._id !== selectedProject._id));
                        setSelectedProject(null);
                    }}
                />
            )}
        </Box>
    );
};

export default DashboardSidebar;