import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Box,
  Button,
  Modal,
  IconButton,
  Paper,
  Grid,
  styled
} from '@mui/material';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import MemberSection from '../MemberSection';
import CloseConfirmationModal from '../../../../components/Modals/CloseConfirmationModal';
import ImageUploadModal from '../../../../components/ImageUploadModal/ImageUploadModal';
import EditProjectModal from '../EditProject/EditProjectModal';

const ColorButton = styled(Button)(({ theme, projectcolor }) => ({
  backgroundColor: projectcolor || theme.palette.primary.main,
  color: theme.palette.getContrastText(projectcolor || theme.palette.primary.main),
  '&:hover': {
    backgroundColor: projectcolor ? theme.palette.augmentColor({
      color: { main: projectcolor }
    }).dark : theme.palette.primary.dark,
  },
}));

const PageHeader = ({ 
  projectTitle, 
  projectDescription, 
  projectNotes, 
  projectColor, 
  projectMembers, 
  accessToken, 
  projectID, 
  user, 
  setProjectDeleted, 
  projectCreator 
}) => {
  const { t } = useTranslation();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  const [openEditProjectModal, setOpenEditProjectModal] = useState(false);

  return (
    <Box sx={{ pt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h4" component="h1" sx={{ mr: 1 }}>
              {projectTitle}
            </Typography>
            {user.sub.split("|")[1] === projectCreator && (
              <IconButton 
                size="small" 
                onClick={() => setOpenEditProjectModal(true)}
                aria-label="edit project"
              >
                <EditSharpIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
          <MemberSection 
            projectMembers={projectMembers} 
            accessToken={accessToken} 
            projectID={projectID} 
            projectName={projectTitle} 
            userID={user.sub.split("|")[1]} 
            projectCreator={projectCreator} 
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ColorButton 
            variant="contained" 
            fullWidth 
            onClick={() => setOpenImageUploadModal(true)}
            projectcolor={projectColor}
          >
            {t('pageHeader.uploadImages')}
          </ColorButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, backgroundColor: 'white' }}>
            <Typography variant="h6" gutterBottom>
              {t('pageHeader.description')}
            </Typography>
            <Typography>
              {projectDescription || t('pageHeader.noDescription')}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, backgroundColor: 'white' }}>
            <Typography variant="h6" gutterBottom>
              {t('pageHeader.notes')}
            </Typography>
            <Typography>
              {projectNotes || t('pageHeader.noNotes')}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Modal
        open={openImageUploadModal}
        onClose={() => setOpenConfirmationModal(true)}
      >
        <Box>
          <ImageUploadModal
            setOpenImageModal={setOpenImageUploadModal}
            accessToken={accessToken}
            userID={user.sub.split("|")[1]}
            nameOfUser={user.name}
            projectID={projectID}
            setOpenConfirmationModal={setOpenConfirmationModal}
          />
        </Box>
      </Modal>

      <CloseConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenImageUploadModal={setOpenImageUploadModal}
      />

      <EditProjectModal
        openEditProjectModal={openEditProjectModal}
        setOpenEditProjectModal={setOpenEditProjectModal}
        accessToken={accessToken}
        projectID={projectID}
        projectTitle={projectTitle}
        projectDescription={projectDescription}
        projectNotes={projectNotes}
        projectColor={projectColor}
        setProjectDeleted={setProjectDeleted}
      />
    </Box>
  );
}

export default PageHeader;