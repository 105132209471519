import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from '@mui/material/styles';
import { ColorButton, theme } from '../../../../theme';
import { EditProject, DeleteProject } from '../../../../services/project.service';
import { useNavigate } from 'react-router-dom';
import DeleteProjectModal from '../DeleteProjectModal/DeleteProjectModal';

const EditProjectModal = ({
  openEditProjectModal,
  setOpenEditProjectModal,
  projectID,
  accessToken,
  projectTitle,
  projectDescription,
  projectNotes,
  projectColor,
  setProjectDeleted,
}) => {
  const { t } = useTranslation();
  const [severityProject, setSeverityProject] = useState('');
  const [messageProject, setMessageProject] = useState('');

  const [editedColor, setEditedColor] = useState(projectColor || '');
  const [editedTitle, setEditedTitle] = useState(projectTitle || '');
  const [editedDescription, setEditedDescription] = useState(
    projectDescription || ''
  );
  const [editedNotes, setEditedNotes] = useState(projectNotes || '');
  const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const colorOptions = [
    '#FC5A5A',
    '#FFA043',
    '#7FF6BC',
    '#83B4FF',
    '#D3BCFC',
    '#FDAEFF',
    '#84818A',
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (openEditProjectModal) {
      setEditedTitle(projectTitle || '');
      setEditedDescription(projectDescription || '');
      setEditedNotes(projectNotes || '');
      setEditedColor(projectColor || '');
      setSeverityProject('');
      setMessageProject('');
    }
  }, [
    openEditProjectModal,
    projectTitle,
    projectDescription,
    projectNotes,
    projectColor,
  ]);

  const closeProjectEditModal = () => {
    setOpenEditProjectModal(false);
    setSeverityProject('');
    setMessageProject('');
  };

  const editProject = async () => {
    if (!editedTitle) {
      setSeverityProject('error');
      setMessageProject(t('editProjectModal.errors.noTitle'));
      return;
    }

    if (!editedColor) {
      setSeverityProject('error');
      setMessageProject(t('editProjectModal.errors.noColor'));
      return;
    }

    if (
      editedTitle === projectTitle &&
      editedDescription === projectDescription &&
      editedNotes === projectNotes &&
      editedColor === projectColor
    ) {
      setSeverityProject('error');
      setMessageProject(t('editProjectModal.errors.noChanges'));
      return;
    }

    setLoading(true);

    try {
      let formData = new FormData();
      formData.append('project_id', projectID);
      formData.append('project_name', editedTitle);
      formData.append('project_description', editedDescription);
      formData.append('project_notes', editedNotes);
      formData.append('project_color', editedColor);

      const response = await EditProject(accessToken, formData);
      if (response.error || response.data.error) {
        setSeverityProject('error');
        setMessageProject(
          response.error?.message ||
            response.data.error ||
            t('editProjectModal.errors.editFailed')
        );
      } else {
        setSeverityProject('success');
        setMessageProject(t('editProjectModal.success.projectUpdated'));
        closeProjectEditModal();
        // Optionally, you can refresh the data or navigate to another page
        // window.location.reload();
      }
    } catch (error) {
      setSeverityProject('error');
      setMessageProject(
        error.message || t('editProjectModal.errors.editFailed')
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openEditProjectModal}
        onClose={closeProjectEditModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {t('editProjectModal.title')}
          <IconButton
            aria-label="close"
            onClick={closeProjectEditModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {messageProject && (
            <Alert severity={severityProject} sx={{ mb: 2 }}>
              {messageProject}
            </Alert>
          )}
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={t('editProjectModal.projectTitle')}
                  variant="outlined"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">
                    {t('editProjectModal.selectColor')}
                  </FormLabel>
                  <RadioGroup
                    row
                    value={editedColor}
                    onChange={(e) => setEditedColor(e.target.value)}
                  >
                    {colorOptions.map((color) => (
                      <FormControlLabel
                        key={color}
                        value={color}
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                display: 'none',
                              },
                              '&.Mui-checked': {
                                '& .color-swatch': {
                                  borderColor: theme.palette.primary.main,
                                },
                              },
                            }}
                            icon={
                              <Box
                                className="color-swatch"
                                sx={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: '50%',
                                  backgroundColor: color,
                                  border: '2px solid transparent',
                                }}
                              />
                            }
                            checkedIcon={
                              <Box
                                className="color-swatch"
                                sx={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: '50%',
                                  backgroundColor: color,
                                  border: '2px solid',
                                  borderColor: theme.palette.primary.main,
                                }}
                              />
                            }
                          />
                        }
                        label=""
                      />
                    ))}
                  </RadioGroup>
                  {!editedColor &&
                    severityProject === 'error' &&
                    messageProject === t('editProjectModal.errors.noColor') && (
                      <FormHelperText error>{messageProject}</FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('editProjectModal.projectDescription')}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('editProjectModal.projectNotes')}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={editedNotes}
                  onChange={(e) => setEditedNotes(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteProjectModal(true)}
            variant="contained"
            color="secondary"
          >
            {t('editProjectModal.deleteProject')}
          </Button>
          <Button
            onClick={editProject}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading
              ? t('editProjectModal.saving')
              : t('editProjectModal.saveChanges')}
          </Button>
        </DialogActions>
        <DeleteProjectModal
          openDeleteProjectModal={openDeleteProjectModal}
          setOpenDeleteProjectModal={setOpenDeleteProjectModal}
          projectID={projectID}
          projectTitle={projectTitle}
          accessToken={accessToken}
          setProjectDeleted={setProjectDeleted}
        />
      </Dialog>
    </ThemeProvider>
  );
};

export default EditProjectModal;
