import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { 
  Avatar, 
  AvatarGroup, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Typography, 
  Box, 
  Button, 
  Alert, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText,
  styled,
  IconButton,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from '@mui/icons-material/Close';
import { SendInvite } from "../../../services/project.service";
import MultiEmailField from "../../../components/MultiEmailField/MultiEmailField";

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MemberSection = ({
  projectName,
  projectMembers,
  accessToken,
  projectID,
  projectCreator,
  userID,
}) => {
  const { t } = useTranslation();
  const [inviteEmails, setInviteEmails] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [memberPopupSeverity, setMemberPopupSeverity] = useState("");
  const [memberPopupMessage, setMemberPopupMessage] = useState("");
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setMemberPopupSeverity("");
    setMemberPopupMessage("");
    setInviteEmails([]);
  };

  const sendInvite = async () => {
    if (!inviteEmails || inviteEmails.length === 0) {
      setMemberPopupSeverity("error");
      setMemberPopupMessage(t('memberSection.errors.noEmail'));
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!inviteEmails.every(email => emailRegex.test(email))) {
      setMemberPopupSeverity("error");
      setMemberPopupMessage(t('memberSection.errors.invalidEmail'));
      return;
    }

    setIsSendingInvite(true);

    try {
      let formData = new FormData();
      formData.append("email", inviteEmails.join(','));
      formData.append("user_id", userID);
      formData.append("project_id", projectID);
      formData.append("project_name", projectName);

      const response = await SendInvite(accessToken, formData);

      if (response.error || response.data.error) {
        setMemberPopupSeverity("error");
        setMemberPopupMessage(
          response.error?.message ||
          response.data.error ||
          t('memberSection.errors.inviteFailed')
        );
      } else {
        setMemberPopupSeverity("success");
        setMemberPopupMessage(t('memberSection.success.inviteSent'));
        setInviteEmails([]);
      }
    } catch (error) {
      setMemberPopupSeverity("error");
      setMemberPopupMessage(
        error.message || t('memberSection.errors.inviteFailed')
      );
    } finally {
      setIsSendingInvite(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography>{t('memberSection.members')}:</Typography>
      <AvatarGroup max={4}>
        {projectMembers.length > 0 ? (
          projectMembers.map((member, index) => (
            <Avatar key={index} alt={member.name} src={member.picture} />
          ))
        ) : (
          <Avatar />
        )}
      </AvatarGroup>
      <Button 
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        startIcon={<MoreHorizIcon />}
      >
        {t('memberSection.manageMembers')}
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {t('memberSection.members')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {memberPopupMessage && (
            <Alert severity={memberPopupSeverity} sx={{ mb: 2 }}>
              {memberPopupMessage}
            </Alert>
          )}
          {projectCreator.toString() === userID && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {t('memberSection.inviteMembers')}
              </Typography>
              <MultiEmailField
                emails={inviteEmails}
                setEmails={setInviteEmails}
                hideLabel={true}
              />
              <ColorButton
                onClick={sendInvite}
                variant="contained"
                fullWidth
                sx={{ mt: 1 }}
                disabled={isSendingInvite}
              >
                {isSendingInvite ? t('memberSection.sending') : t('memberSection.invite')}
              </ColorButton>
            </Box>
          )}
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {t('memberSection.currentMembers')}
          </Typography>
          {projectMembers.length > 0 ? (
            <List>
              {projectMembers.map((member, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar alt={member.name} src={member.picture} />
                  </ListItemAvatar>
                  <ListItemText 
                    primary={member.name || t('memberSection.unknownMember')}
                    secondary={projectCreator.toString() === member._id ? t('memberSection.admin') : null}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>{t('memberSection.noMembers')}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('memberSection.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MemberSection; 
