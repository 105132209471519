import React, { useState, useMemo } from 'react';
import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    TablePagination,
    Alert,
    useMediaQuery,
    Typography,
    Grid,
    CircularProgress
} from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';

import './ResultsConfirmation.css';
import { SaveToProject } from '../../../services/project.service';
import { theme, ColorButton, InverseButton } from '../../../theme';
import { BloodSmearRBCTypes, BloodSmearWBCTypes } from '../../../utils/enums';
import { createCSV } from '../../../utils/utils';
import { DNALadders, RNALadders, ProteinLadders } from '../../../utils/enums';

const ResultsConfirmation = ({
    setReviewImagesModal,
    trialRunUploaded,
    setTrialRunUploaded,
    accessToken,
    trialName,
    assayType,
    volumePlated,
    volumeUnits,
    dilutionFactor,
    fileList,
    fileNames,
    boundingBoxesList,
    sensitivity,
    imageNotes,
    setConfirmResults,
    nameOfUser,
    trialStartDate,
    trialEndDate,
    newCounts,
    adjustedBoundingBoxes,
    enumeratedBy,
    trialID,
    bandMeasurements,
    class0MeasurementsInClass4,
    selectedTemplateLadder,
    selectedWells,
    BPValueToFind,
    BPMarginOfError
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createCsvExport = () => {
        if (assayType == "Gel Electrophoresis") {
            // Extract all potential keys (well numbers) from class0MeasurementsInClass4
            const allKeys = new Set();
            Object.values(class0MeasurementsInClass4).forEach(imageData => {
                Object.keys(imageData).forEach(key => allKeys.add(key));
            });
            const sortedKeys = Array.from(allKeys).sort((a, b) => parseInt(a) - parseInt(b));

            // Create project images data
            const uploadedImages = Object.entries(class0MeasurementsInClass4).map(([imageIndex, wellData]) => {
                const imageData = {
                    image_name: fileNames[imageIndex],
                    uploaded_on: new Date().toLocaleDateString(),
                };

                sortedKeys.forEach(key => {
                    const wellNumber = `Well_${parseInt(key) + 1}`;
                    if (wellData[key]) {
                        // Join multiple values with a semicolon
                        imageData[wellNumber] = wellData[key].join(';');
                    } else {
                        imageData[wellNumber] = '';
                    }
                });

                return imageData;
            });

            createCSV(null, null, null, uploadedImages);
            return;
        }

        const projectImages = fileList.map((file, index) => ({
            image_name: fileNames[index],
            colony_count: newCounts[index],
            dilution_factor: dilutionFactor[index],
            cfu_per_ml: calculateCFU(index),
            sensitivity: sensitivity[index],
            uploaded_on: new Date().toLocaleDateString(),
            ...(assayType === "GFP/OFP" && {
                gfp_count: adjustedBoundingBoxes[index].filter((box) => box[5] === 0).length,
                ofp_count: adjustedBoundingBoxes[index].filter((box) => box[5] === 1).length,
                ratio: (adjustedBoundingBoxes[index].filter((box) => box[5] === 0).length / adjustedBoundingBoxes[index].length).toFixed(2)
            }),
            ...(assayType === "Membrane" && {
                other_coliforms: adjustedBoundingBoxes[index].filter((box) => box[5] === 0).length,
                e_coli: adjustedBoundingBoxes[index].filter((box) => box[5] === 1).length
            }),
            ...(assayType === "Smear" && Object.keys(BloodSmearRBCTypes).reduce((acc, key, i) => ({
                ...acc,
                [BloodSmearRBCTypes[key]]: adjustedBoundingBoxes[index].filter((box) => box[5] === i).length
            }), {})),
            ...(assayType === "Smear WBC" && Object.keys(BloodSmearWBCTypes).reduce((acc, key, i) => ({
                ...acc,
                [BloodSmearWBCTypes[key]]: adjustedBoundingBoxes[index].filter((box) => box[5] === i).length
            }), {}))
        }));

        createCSV(null, null, null, projectImages);
    };

    const showMeasurementError = (measurements) => {
        // check if measurement is a value between BPValueToFind - BPMarginOfError and BPValueToFind + BPMarginOfError
        if (measurements.length === 0 || BPValueToFind === "" || BPMarginOfError === "") {
            return false;
        }
        var BPInRange = false;
        measurements.forEach(measure => {
            // convert measure to integer
            measure = parseInt(measure);
            let highBound = parseInt(BPValueToFind) + parseInt(BPMarginOfError);
            let lowBound = parseInt(BPValueToFind) - parseInt(BPMarginOfError);
            console.log(measure, lowBound, highBound);
            if (measure >= lowBound && measure <= highBound) {
                BPInRange = true;
            }
        });
        return BPInRange;
    };

    const calculateCFU = (index) => {
        if (dilutionFactor[index] && volumePlated) {
            const factor = volumeUnits === 'ml' ? 1 : 1000;
            return ((newCounts[index] * dilutionFactor[index]) / (volumePlated * factor)).toFixed(2);
        }
        return t('common.notSet');
    };

    const exportImages = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const filesToExport = [];

        const processImage = (index) => {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = URL.createObjectURL(fileList[index]);
                image.onload = () => {
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0);
                    boundingBoxesList[index].forEach(([xmin, ymin, xmax, ymax, confidence, cls]) => {
                        ctx.strokeStyle = cls === 0 ? 'green' : 'red';
                        ctx.lineWidth = 2;
                        ctx.strokeRect(xmin, ymin, xmax - xmin, ymax - ymin);
                    });
                    canvas.toBlob((blob) => {
                        filesToExport.push(new File([blob], fileNames[index], { type: 'image/jpeg', lastModified: Date.now() }));
                        resolve();
                    }, 'image/jpeg');
                };
            });
        };

        Promise.all(fileList.map((_, index) => processImage(index)))
            .then(() => {
                const zip = new JSZip();
                filesToExport.forEach((file) => zip.file(file.name, file));
                return zip.generateAsync({ type: 'blob' });
            })
            .then((content) => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(content);
                a.download = `${new Date().toLocaleDateString()}_${trialName}_${assayType}_Images.zip`;
                a.click();
            });
    };

    const SaveImageToProject = () => {
        setIsSaving(true);
        const formData = new FormData();
        const appendFormData = (key, value) => formData.append(key, value);

        appendFormData('trial_id', trialID);
        appendFormData('name', trialName);
        appendFormData('assay_type', assayType);
        if (assayType !== "Gel Electrophoresis") {
            appendFormData('volume_plated', volumePlated);
            appendFormData('volume_unit', volumeUnits);
            appendFormData('dilution_factor', dilutionFactor);
            appendFormData('bounding_box_counts', newCounts);
            appendFormData('sensitivity', sensitivity);

        }
        appendFormData('bounding_boxes', JSON.stringify(adjustedBoundingBoxes));
        appendFormData('enumerated_by', enumeratedBy);
        appendFormData('file_names', fileNames);
        appendFormData('start_date', trialStartDate);
        appendFormData('end_date', trialEndDate);
        appendFormData('image_notes', imageNotes);
        appendFormData('uploaded_by', nameOfUser);

        if (assayType === "GFP/OFP") {
            appendFormData('gfp_counts', adjustedBoundingBoxes.map((box) => box.filter((b) => b[5] === 0).length));
            appendFormData('ofp_counts', adjustedBoundingBoxes.map((box) => box.filter((b) => b[5] === 1).length));
        } else if (assayType === "Membrane") {
            appendFormData('other_coliforms', adjustedBoundingBoxes.map((box) => box.filter((b) => b[5] === 0).length));
            appendFormData('e_coli', adjustedBoundingBoxes.map((box) => box.filter((b) => b[5] === 1).length));
        } else if (assayType === "Smear") {
            Object.keys(BloodSmearRBCTypes).forEach((key, index) => {
                appendFormData(BloodSmearRBCTypes[key], adjustedBoundingBoxes.map((box) => box.filter((b) => b[5] === index).length));
            });
        } else if (assayType === "Smear WBC") {
            Object.keys(BloodSmearWBCTypes).forEach((key, index) => {
                appendFormData(BloodSmearWBCTypes[key], adjustedBoundingBoxes.map((box) => box.filter((b) => b[5] === index).length));
            });
        } else if (assayType === "Gel Electrophoresis") {
            appendFormData('band_measurements', bandMeasurements);
            appendFormData('selected_wells', selectedWells);
            appendFormData('bp_value_to_find', BPValueToFind);
            appendFormData('bp_margin_of_error', BPMarginOfError);
            appendFormData('template_ladder', Object.keys(DNALadders).find(key => DNALadders[key] === selectedTemplateLadder) || Object.keys(RNALadders).find(key => RNALadders[key] === selectedTemplateLadder) || Object.keys(ProteinLadders).find(key => ProteinLadders[key] === selectedTemplateLadder) || '');
        }

        fileList.forEach((file) => appendFormData('files', file));

        SaveToProject(accessToken, formData).then(() => {
            setTrialRunUploaded(true);
            setSeverity('success');
            setMessage(t('selectImages.resultsConfirmation.messages.imagesSaved'));
            setTimeout(() => {
                setSeverity('');
                setMessage('');
                window.location.reload();
            }, 2000);
        }).catch((error) => {
            setSeverity('error');
            setMessage(t('selectImages.resultsConfirmation.messages.saveFailed'));
        }).finally(() => {
            setIsSaving(false);
        });
    };

    const summaryHeadCells = useMemo(() => {
        const baseCells = [
            { id: 'trialName', label: t('selectImages.resultsConfirmation.summaryTable.trialName') },
            { id: 'assayType', label: t('selectImages.resultsConfirmation.summaryTable.assayType') },
        ];

        if (assayType !== "Gel Electrophoresis") {
            return [
                ...baseCells,
                { id: 'volumePlated', label: t('selectImages.resultsConfirmation.summaryTable.volumePlated') },
                { id: 'colonyCount', label: t('selectImages.resultsConfirmation.summaryTable.colonyCount') },
                { id: 'averageColonyCount', label: t('selectImages.resultsConfirmation.summaryTable.averageColonyCount') },
            ];
        } else {
            return [
                ...baseCells,
                { id: 'SelectedLadder', label: t('selectImages.resultsConfirmation.summaryTable.selectedLadder') },
                { id: 'BPValueToFind', label: t('selectImages.resultsConfirmation.summaryTable.BPRange') }
            ]
        }

        return baseCells;
    }, [t, assayType, volumePlated, newCounts]);

    const getDetailedHeadCells = useMemo(() => {
        const baseCells = [
            { id: 'imageName', label: t('selectImages.resultsConfirmation.detailedTable.imageName') },
            { id: 'colonyCount', label: t('selectImages.resultsConfirmation.detailedTable.colonyCount') },
            { id: 'dilutionFactor', label: t('selectImages.resultsConfirmation.detailedTable.dilutionFactor') },
            { id: 'cfuPerMl', label: t('selectImages.resultsConfirmation.detailedTable.cfuPerMl') },
            { id: 'sensitivity', label: t('selectImages.resultsConfirmation.detailedTable.sensitivity') },
            { id: 'uploadedOn', label: t('selectImages.resultsConfirmation.detailedTable.uploadedOn') },
        ];

        if (assayType === "GFP/OFP") {
            return [
                baseCells[0],
                { id: 'TotalColonyCount', label: t('selectImages.resultsConfirmation.detailedTable.totalCount') },
                { id: 'gfpCount', label: t('selectImages.resultsConfirmation.detailedTable.gfpCount') },
                { id: 'ofpCount', label: t('selectImages.resultsConfirmation.detailedTable.ofpCount') },
                { id: 'Ratio', label: t('selectImages.resultsConfirmation.detailedTable.ratio') },
                ...baseCells.slice(2)
            ];
        } else if (assayType === "Membrane") {
            return [
                baseCells[0],
                { id: 'TotalColonyCount', label: t('selectImages.resultsConfirmation.detailedTable.totalCount') },
                { id: 'Other Coliforms', label: t('selectImages.resultsConfirmation.detailedTable.otherColiforms') },
                { id: 'E. Coli', label: t('selectImages.resultsConfirmation.detailedTable.eColi') },
                ...baseCells.slice(2)
            ];
        } else if (assayType === "Gel Electrophoresis") {
            // remove all cells except the first and last
            return [baseCells[0], baseCells[baseCells.length - 1]];
        }

        return baseCells;
    }, [t, assayType]);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                paddingBottom: '60px' // Add padding to account for the sticky footer
            }}>
                <Typography variant="h5" sx={{ mb: 2 }}>{t('selectImages.resultsConfirmation.overallResults')}</Typography>
                <TableContainer component={Paper} sx={{ mb: 4, maxWidth: '100%' }}>
                    <Table size={isMobile ? "small" : "medium"} sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                {summaryHeadCells.map((cell) => (
                                    <TableCell key={cell.id} align="center">{cell.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{trialName}</TableCell>
                                <TableCell align="center">{assayType}</TableCell>
                                {assayType === "Gel Electrophoresis" && <TableCell align="center">{selectedTemplateLadder.name}</TableCell>}
                                {assayType === "Gel Electrophoresis" && <TableCell align="center">{BPValueToFind ? `${BPValueToFind} ± ${BPMarginOfError}` : t('common.notSet')}</TableCell>}
                                {assayType !== "Gel Electrophoresis" && <TableCell align="center"> {(volumePlated ? `${volumePlated}${volumeUnits}` : t('common.notSet'))} </TableCell>}
                                {assayType !== "Gel Electrophoresis" && <TableCell align="center">{newCounts.reduce((acc, curr) => acc + curr, 0)}</TableCell>}
                                {assayType !== "Gel Electrophoresis" && <TableCell align="center">{(newCounts.reduce((acc, curr) => acc + curr, 0) / newCounts.length).toFixed(2)}</TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="h5" sx={{ mb: 2 }}>{t('selectImages.resultsConfirmation.individualResults')}</Typography>
                <TableContainer component={Paper} sx={{ mb: 4, maxWidth: '100%', overflowX: 'auto' }}>
                    <Table size={isMobile ? "small" : "medium"} sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                {getDetailedHeadCells.map((cell) => (
                                    <TableCell key={cell.id} align="center">{cell.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fileList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file, index) => {
                                const actualIndex = index + page * rowsPerPage;
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <img src={URL.createObjectURL(file)} alt={fileNames[actualIndex]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                                <Typography variant="caption">{fileNames[actualIndex]}</Typography>
                                            </Box>
                                        </TableCell>
                                        {assayType !== "Gel Electrophoresis" && <TableCell align="center">{newCounts[actualIndex]}</TableCell>}
                                        {(assayType === "GFP/OFP" || assayType === "Membrane") && (
                                            <>
                                                <TableCell align="center">{adjustedBoundingBoxes[actualIndex].filter((box) => box[4] === 0).length}</TableCell>
                                                <TableCell align="center">{adjustedBoundingBoxes[actualIndex].filter((box) => box[4] === 1).length}</TableCell>
                                            </>
                                        )}
                                        {assayType === "GFP/OFP" && (
                                                <TableCell align="center">
                                                    {(adjustedBoundingBoxes[actualIndex].filter((box) => box[4] === 0).length / adjustedBoundingBoxes[actualIndex].length).toFixed(2)}
                                                </TableCell>            
                                        )}
                                        {assayType !== "Gel Electrophoresis" && <TableCell align="center">{dilutionFactor[actualIndex] || t('common.notSet')}</TableCell>}
                                        {assayType !== "Gel Electrophoresis" && <TableCell align="center">{calculateCFU(actualIndex)}</TableCell>}
                                        {assayType !== "Gel Electrophoresis" && <TableCell align="center">{sensitivity[actualIndex]}%</TableCell>}
                                        <TableCell align="center">{new Date().toLocaleDateString()}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={fileList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ mb: 2 }}
                />

                {/* Alert messages */}
                {(severity === 'error' || severity === 'success') && (
                    <Alert severity={severity} sx={{ mt: 2 }}>{message}</Alert>
                )}
            </Box>


            {/* Sticky footer */}
            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderTop: '1px solid #e0e0e0',
                    backgroundColor: 'background.paper',
                    padding: 2,
                    zIndex: 1000 
                }}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <InverseButton
                            onClick={() => {
                                setConfirmResults(false);
                                setReviewImagesModal(true);
                            }}
                            variant='outlined'
                            disabled={isSaving}
                        >
                            {t('selectImages.resultsConfirmation.buttons.back')}
                        </InverseButton>
                    </Grid>
                    <Grid item>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <ColorButton
                                onClick={exportImages}
                                disabled={isSaving}
                            >
                                {t('selectImages.resultsConfirmation.buttons.exportImages')}
                            </ColorButton>
                            <ColorButton
                                onClick={createCsvExport}
                                disabled={isSaving}
                            >
                                {assayType === "Gel Electrophoresis" ? t('selectImages.resultsConfirmation.buttons.exportCsvGel') :
                                    t('selectImages.resultsConfirmation.buttons.exportCsv')}
                            </ColorButton>
                            <ColorButton
                                onClick={SaveImageToProject}
                                disabled={trialRunUploaded || isSaving}
                            >
                                {isSaving ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : trialRunUploaded ? (
                                    t('selectImages.resultsConfirmation.buttons.uploaded')
                                ) : (
                                    t('selectImages.resultsConfirmation.buttons.saveToTrial')
                                )}
                            </ColorButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default ResultsConfirmation;