import React, { useState } from "react";
import FileUploadComponent from './FileUpload/FileUploadComponent';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    ThemeProvider,
    Box,
    Typography,
    Alert,
    Paper
} from "@mui/material";
import { useTranslation } from "react-i18next";
// Removed custom CSS import
// import "./SelectImages.css";
import { options } from "../../../utils/enums";
import { theme, ColorButton } from "../../../theme";
import TemplateLadderDropdown from "../../GelElectrophoresisEditor/TemplateLadderDropdown";

const SelectImages = ({
    ProcessImages,
    dilutionFactor,
    volumeUnits,
    assayType,
    setDilutionFactor,
    setVolumeUnits,
    setVolumePlated,
    setFileList,
    setFilenames,
    setAssayType,
    fileList,
    fileNames,
    setSeverity,
    severity,
    message,
    setMessage,
    selectedTemplateLadder,
    setSelectedTemplateLadder,
    setBandMeasurements,
    setBPValueToFind,
    BPMarginOfError,
    setBPMarginOfError,
}) => {
    const [filesReady, setFilesReady] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const { t } = useTranslation();

    const onFileSubmit = () => {
        console.log("File Submitted");
        if (assayType === "" || assayType === null) {
            setMessage(t("selectImages.errors.selectAssayType"));
            setSeverity("error");
            setTimeout(() => {
                setSeverity(null);
                setMessage("");
            }, 3000);
            return;
        }
        if (filesReady === false) {
            setMessage(t("selectImages.errors.uploadImage"));
            setSeverity("error");
            setTimeout(() => {
                setSeverity(null);
                setMessage("");
            }, 3000);
            return;
        }
        if (
            filesReady &&
            fileList.length > 0 &&
            fileList.length === fileNames.length &&
            !uploadError
        ) {
            setDilutionFactor(new Array(fileList.length).fill(dilutionFactor));
            ProcessImages();
        } else {
            setMessage(t("selectImages.errors.uploadImage"));
            setSeverity("error");
            setTimeout(() => {
                setSeverity(null);
                setMessage("");
            }, 3000);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    maxHeight: "100vh",
                    maxWidth: "100%",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                        {/* Assay Type */}
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel required>
                                {t("selectImages.assayType.label")}
                            </InputLabel>
                            <Select
                                label={t("selectImages.assayType.label")}
                                onChange={(e) => setAssayType(e.target.value)}
                                value={assayType}
                            >
                                {options.map((option) => (
                                    <MenuItem value={option.value} key={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Volume Plated */}
                        {assayType !== "Gel Electrophoresis" && (
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t("selectImages.volumePlated.label")}</InputLabel>
                                <Input
                                    type="number"
                                    onChange={(e) => setVolumePlated(e.target.value)}
                                    endAdornment={
                                        <Select
                                            value={volumeUnits || "ml"}
                                            onChange={(e) => setVolumeUnits(e.target.value)}
                                            variant="standard"
                                        >
                                            <MenuItem value="ml">ml</MenuItem>
                                            <MenuItem value="ul">μl</MenuItem>
                                        </Select>
                                    }
                                />
                            </FormControl>
                        )}

                        {/* Dilution Factor */}
                        {assayType !== "Gel Electrophoresis" && (
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{t("selectImages.dilutionFactor.label")}</InputLabel>
                                <Input
                                    type="number"
                                    onChange={(e) => setDilutionFactor(e.target.value)}
                                />
                            </FormControl>
                        )}

                        {/* Ladder Band (E Gel Only) */}
                        {assayType === "Gel Electrophoresis" && (
                            <>
                                <FormControl variant="outlined" fullWidth>
                                    <TemplateLadderDropdown
                                        setSelectedTemplateLadder={setSelectedTemplateLadder}
                                        selectedTemplateLadder={selectedTemplateLadder}
                                        setBandMeasurements={setBandMeasurements}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>{t("selectImages.bpValueToFind.label")}</InputLabel>
                                    <Input
                                        type="number"
                                        onChange={(e) => setBPValueToFind(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>
                                        {t("selectImages.bpMarginOfError.label")}
                                    </InputLabel>
                                    <Input
                                        type="number"
                                        onChange={(e) => setBPMarginOfError(e.target.value)}
                                    />
                                </FormControl>
                            </>
                        )}
                    </Box>
                    {assayType === "Gel Electrophoresis" && (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ marginBottom: 2 }}
                        >
                            {t("selectImages.eGelNote")}
                        </Typography>
                    )}
                </Box>

                <Box sx={{ width: "100%", marginTop: 2, marginBottom: 10, pb: { xs: 10, md: 0 } }}>
                    <FileUploadComponent
                        setFilesReady={setFilesReady}
                        setUploadError={setUploadError}
                        fileList={fileList}
                        setFileList={setFileList}
                        fileNames={fileNames}
                        setFilenames={setFilenames}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        rotateImage={assayType !== "Gel Electrophoresis"}
                    />
                </Box>
            </Box>
            <Paper
                sx={{ 
                    position: 'absolute', 
                    bottom: 0, 
                    left: 0, 
                    right: 0,
                    backgroundColor: 'white',
                    borderTop: '1px solid #e0e0e0',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    boxShadow: 2,
                 }}
                 elevation={3}
                 borderRadius={0}
            >
                {(severity === "error" || severity === "success") && (
                    <Alert severity={severity} sx={{ marginBottom: 2 }}>
                        {message}
                    </Alert>
                )}
                <ColorButton variant="contained" onClick={onFileSubmit} sx={{ m: 2 }}>
                    {t("selectImages.analyzeImages")}
                </ColorButton>
            </Paper>
        </ThemeProvider>
    );
};

export default SelectImages;
