import React, { useState, useEffect, useLayoutEffect } from "react";
import { AppBar, Toolbar, IconButton, SwipeableDrawer, Typography, Box, Button, Badge } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/images/Logos/Logo.png";
import CompactLanguageSwitcher from "../langSwitch/CompactLanguageSwitcher";
import { ThemeProvider } from "@mui/system";
import DashboardSidebar from "../Sidebar/dashboard-sidebar";
import { useMediaQuery } from "@mui/material";
import { theme, ColorButton, StyledLogo } from "../../theme";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    height: 70,
    [theme.breakpoints.down('sm')]: {
        padding: "0 5px",
    },
}));

const NavBar = ({ userData, InfoUpdated, projectDeleted, user }) => {
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);

    const [hasNewNotification, setHasNewNotification] = useState(false);

    useEffect(() => {
        if (!user) {
            return;
        }
        const fetchToken = async () => {
            const token = await getAccessTokenSilently();
            setToken(token);
        };
        fetchToken();
    }, [user, getAccessTokenSilently]);

    const isCompact = useMediaQuery(theme.breakpoints.down('md'));

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useLayoutEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        if (windowSize.width > 1024) {
            setSideBarOpen(false);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderEnvironmentTag = () => (
        <ThemeProvider theme={theme}>
        <Box sx={{ backgroundColor: "primary.main", borderRadius: 5, p: 1, ml: 1 }}>
            <Typography variant="text" color="white">
                {t(`environment.${process.env.REACT_APP_ENV}`)}
            </Typography>
        </Box>
        </ThemeProvider>
    );

    const handleLogin = () => {
        loginWithRedirect();
    };

    return (
        <ThemeProvider theme={theme}>
            <StyledAppBar position="fixed" elevation={0}>
                <StyledToolbar>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: isCompact ? 1 : 0 }}>
                        <StyledLogo
                            src={Logo}
                            alt="logo"
                            onClick={() => navigate(isAuthenticated ? "/dashboard" : "/")}
                        />
                        {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "production"
                         && renderEnvironmentTag()}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontSize: 16 }}>
                        <CompactLanguageSwitcher />
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={!hasNewNotification}
                            overlap="circular"
                        >
                            <IconButton
                                color="inherit"
                                sx={{ fontSize: '1.5rem' }}
                                onClick={() => setSideBarOpen(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Badge>
                        {!isAuthenticated && (
                            <ColorButton onClick={handleLogin}>
                                {t("navigation.login")}
                            </ColorButton>
                        )}
                    </Box>
                </StyledToolbar>

                <SwipeableDrawer
                    anchor="left"
                    open={sideBarOpen}
                    onClose={() => setSideBarOpen(false)}
                    onOpen={() => setSideBarOpen(true)}
                >
                    <Box sx={{ width: 300 }} role="presentation">
                        <DashboardSidebar
                            token={token}
                            user={user}
                            InfoUpdated={InfoUpdated}
                            userData={userData}
                            projectDeleted={projectDeleted}
                            isAuthenticated={isAuthenticated}
                            handleClose={() => setSideBarOpen(false)}
                            hasNewNotification={hasNewNotification}
                            setHasNewNotification={setHasNewNotification}
                        />
                    </Box>
                </SwipeableDrawer>
            </StyledAppBar>
        </ThemeProvider>
    );
};

export default NavBar;