import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { modalStyle, ColorButton, InverseButton } from '../../../theme';

const DeleteModal = ({ 
  modalTitleKey, 
  openDeleteConfirmationDialogue, 
  setOpenDeleteConfirmationDialouge, 
  deleteMessageKey, 
  OnConfirmMethod 
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={openDeleteConfirmationDialogue}
      onClose={() => setOpenDeleteConfirmationDialouge(false)}
    >
      <Box sx={{ ...modalStyle }}>
        <div className="modal-header">
          <h2 className="modal-title">{t(modalTitleKey)}</h2>
          <div
            className="modal-close"
            onClick={() => setOpenDeleteConfirmationDialouge(false)}
          >
            {t('common.close')}
          </div>
        </div>
        <div className="modal-body">
          <text>{t(deleteMessageKey)}</text>
          <div className="button-container">
            <ColorButton variant="contained" onClick={OnConfirmMethod}>
              {t('common.confirm')}
            </ColorButton>
            <InverseButton
              onClick={() => setOpenDeleteConfirmationDialouge(false)}
            >
              {t('common.cancel')}
            </InverseButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteModal;