import React, { useRef, useEffect, useState } from "react";
import {
	Alert,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	Slider,
	Typography,
	Grid,
	CircularProgress,
	Box,
	useMediaQuery,
} from "@mui/material";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
	GetProjectImage,
	UpdateImage,
} from "../../services/project.service";
import {
	BloodSmearRBCTypes,
	BloodSmearWBCTypes,
	GFP_OFP_Classes,
	Assay7Classes,
} from "../../utils/enums";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../theme"; // Importing from your theme.js

const BBoxEditor = ({
	adjustedBoundingBoxes,
	newCounts,
	index,
	imageFile,
	accessToken,
	cellTypes,
	imageData,
	setOpenImageEditor,
	multiclassModel,
	setAdjustedBoundingBoxes,
	showHelperMessage,
	horizontalControls,
	saveButton,
	setNewCounts,
	hideExtraControls,
}) => {
	const { t } = useTranslation();
	const canvasRef = useRef(null);
	const boundingBoxesRef = useRef(null);

	const [boundingBoxSize, setBoundingBoxSize] = useState(20);
	const [cellTypeID, setCellTypeID] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [base64Image, setBase64Image] = useState(null);
	const [editImageSeverity, setEditImageSeverity] = useState("");
	const [editImageMessage, setEditImageMessage] = useState("");
	const [thickness, setThickness] = useState(5);
	const [isCircles, setIsCircles] = useState(true);
	const [colors] = useState([
		"#00FF00",
		"#FF0000",
		"#0000FF",
		"#FFFF00",
		"#FF00FF",
		"#00FFFF",
		"#FFA500",
		"#800080",
		"#008000",
		"#000080",
		"#800000",
		"#008080",
		"#808000",
		"#808080",
	]);
	const [loading, setLoading] = useState(true);
	const [sensitivity, setSensitivity] = useState(imageData.sensitivity || 85);
	const [horizontal, setHorizontal] = useState(horizontalControls);
	const [totalColonyCount, setTotalColonyCount] = useState(
		imageData.bounding_boxes.length
	);
	const [ratio, setRatio] = useState(0);

	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	useEffect(() => {
		setHorizontal(isSmallScreen);
	}, [isSmallScreen]);

	useEffect(() => {
		if (adjustedBoundingBoxes && index !== null && setNewCounts) {
			boundingBoxesRef.current = adjustedBoundingBoxes[index];
			setTotalColonyCount(adjustedBoundingBoxes[index].length);
			renderCanvas();
		} else if (imageData && imageData.bounding_boxes) {
			boundingBoxesRef.current = imageData.bounding_boxes;
			setTotalColonyCount(imageData.bounding_boxes.length);
			renderCanvas();
		}
	}, [index]);

	const SaveChangesOnImage = () => {
		let formData = new FormData();

		if (imageData.assay_type === "GFP/OFP") {
			imageData.gfp_counts = boundingBoxesRef.current.filter(
				(box) => box[4] === 0 && ((box[5] - 0.2) / 0.8) * 100 < sensitivity
			).length;
			imageData.ofp_counts = boundingBoxesRef.current.filter(
				(box) => box[4] === 1 && ((box[5] - 0.2) / 0.8) * 100 < sensitivity
			).length;
		} else if (imageData.assay_type === "E. coli") {
			imageData.ecoli_counts = boundingBoxesRef.current.filter(
				(box) => box[4] === 0 && ((box[5] - 0.2) / 0.8) * 100 < sensitivity
			).length;
			imageData.other_coliforms_counts = boundingBoxesRef.current.filter(
				(box) => box[4] === 1 && ((box[5] - 0.2) / 0.8) * 100 < sensitivity
			).length;
		} else if (imageData.assay_type === "Smear") {
			Object.keys(BloodSmearRBCTypes).forEach((key, index) => {
				let count = boundingBoxesRef.current.filter(
					(box) =>
						box[4] === index && ((box[5] - 0.2) / 0.8) * 100 < sensitivity
				).length;
				imageData[key] = count;
			});
		}

		imageData.total_detections = totalColonyCount;
		imageData.sensitivity = sensitivity;
		imageData.bounding_boxes = boundingBoxesRef.current;

		formData.append("image", JSON.stringify(imageData));

		UpdateImage(accessToken, formData).then((response) => {
			if (response.error) {
				setEditImageSeverity("error");
				setEditImageMessage(t("bboxEditor.imageUpdateError"));
			} else {
				setEditImageSeverity("success");
				setEditImageMessage(t("bboxEditor.imageUpdateSuccess"));
			}
			setTimeout(() => {
				setEditImageSeverity("");
				setEditImageMessage("");
			}, 3000);
		});
	};

	useEffect(() => {
		async function fetchImage() {
			setLoading(true);
			if (imageData !== null && accessToken !== null) {
				let formData = new FormData();
				formData.append("image_id", imageData._id);
				formData.append(
					"filename",
					imageData._id + "." + imageData.filename.split(".").pop()
				);
				formData.append("trial_id", imageData.trial_id);

				const response = await GetProjectImage(accessToken, formData);
				if (response.error) {
					setEditImageSeverity("error");
					setEditImageMessage(t("bboxEditor.imageNotFound"));
				} else {
					setBase64Image(response.data.image);
				}
			} else if (
				accessToken === null &&
				imageFile !== null &&
				imageData !== null
			) {
				setBase64Image(imageFile);
			} else {
				setEditImageSeverity("error");
				setEditImageMessage(t("bboxEditor.noBoundingBoxes"));
			}
			setLoading(false);
		}
		fetchImage();
	}, [imageData, accessToken, imageFile, t]);

	useEffect(() => {
		renderCanvas();
	}, [
		base64Image,
		sensitivity,
		cellTypeID,
		boundingBoxSize,
		thickness,
		isCircles,
		totalColonyCount,
	]);

	const handleSensitiveChange = (value) => {
		setSensitivity(value);
		setTotalColonyCount(
			boundingBoxesRef.current.filter(
				(box) => ((box[5] - 0.2) / 0.8) * 100 < value
			).length
		);
	};

	useEffect(() => {
		const gfpCount = boundingBoxesRef.current.filter(
			(box) =>
				box[4] === 0 && ((box[5] - 0.2) / 0.8) * 100 < sensitivity
		).length;

		const ofpCount = boundingBoxesRef.current.filter(
			(box) =>
				box[4] === 1 && ((box[5] - 0.2) / 0.8) * 100 < sensitivity
		).length;

		setRatio((gfpCount / ofpCount).toFixed(2));
	}, [sensitivity]);

	const renderCanvas = () => {
		const canvas = canvasRef.current;
		if (!canvas) return;
		const context = canvas.getContext("2d");

		const image = new Image();
		image.src = "data:image/jpg;base64," + base64Image;

		image.onload = () => {
			canvas.width = image.width * zoom;
			canvas.height = image.height * zoom;
			context.clearRect(0, 0, canvas.width, canvas.height);
			context.drawImage(image, 0, 0, canvas.width, canvas.height);

			let counter = 0;
			let adjustedBoundingBoxes = [];
			let boundingBoxes = boundingBoxesRef.current;

			// Check if boundingBoxes is an array before iterating
			if (Array.isArray(boundingBoxes)) {
				boundingBoxes.forEach((box) => {
					// Check if box is an array with the correct length (should have 6 elements)
					if (Array.isArray(box) && box.length === 6) {
						const [xmin, ymin, xmax, ymax, cls, confidence] = box;
						const normalizedConfidence = (confidence - 0.2) / 0.8;
						if (normalizedConfidence * 100 < sensitivity) {
							adjustedBoundingBoxes.push(box);
							counter++;
							const centerX = (xmin + xmax) / 2;
							const centerY = (ymin + ymax) / 2;
							context.strokeStyle = colors[cls];

							if (multiclassModel) {
								context.font = "14px Avenir Next Cyr";
								context.fillStyle = "#000000";
								context.fillText(cls, xmin * zoom, ymin * zoom - 5);
								context.strokeStyle = colors[cls];
							}

							context.lineWidth = thickness;
							if (isCircles) {
								context.beginPath();
								context.arc(
									centerX * zoom,
									centerY * zoom,
									((xmax - xmin) / 2) * zoom,
									0,
									2 * Math.PI
								);
								context.stroke();
							} else {
								context.beginPath();
								context.rect(
									xmin * zoom,
									ymin * zoom,
									(xmax - xmin) * zoom,
									(ymax - ymin) * zoom
								);
								context.stroke();
							}
						}
					}
				});
			}

			if (setAdjustedBoundingBoxes && index !== null) {
				setAdjustedBoundingBoxes((prevBoxes) => {
					const newBoxes = [...prevBoxes];
					newBoxes[index] = adjustedBoundingBoxes;
					return newBoxes;
				});
			}

			if (setNewCounts && index !== null) {
				setNewCounts((prevCounts) => {
					const newCounts = [...prevCounts];
					newCounts[index] = counter;
					return newCounts;
				});
			}
		};
	};

	const handleCanvasClick = (e) => {
		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();
		const x =
			((e.clientX - rect.left) * (canvas.width / rect.width)) / zoom;
		const y =
			((e.clientY - rect.top) * (canvas.height / rect.height)) / zoom;

		let boundingBoxes = boundingBoxesRef.current;
		let boxRemoved = false;

		if (boundingBoxes && Array.isArray(boundingBoxes)) {
			boundingBoxesRef.current = boundingBoxes.filter((box) => {
				const [xmin, ymin, xmax, ymax, cls, confidence] = box;
				const normalizedConfidence = (confidence - 0.2) / 0.8;
				if (normalizedConfidence * 100 < sensitivity) {
					if (x > xmin && x < xmax && y > ymin && y < ymax) {
						setTotalColonyCount(totalColonyCount - 1);
						boxRemoved = true;
						return false;
					}
				}
				return true;
			});
		}

		if (!boxRemoved) {
			setTotalColonyCount(totalColonyCount + 1);
			const xmin = x - boundingBoxSize / 2;
			const ymin = y - boundingBoxSize / 2;
			const xmax = x + boundingBoxSize / 2;
			const ymax = y + boundingBoxSize / 2;
			boundingBoxesRef.current.push([
				xmin,
				ymin,
				xmax,
				ymax,
				cellTypeID,
				0.8,
			]);
		}

		renderCanvas();
	};

	const undoChanges = () => {
		boundingBoxesRef.current = imageData.bounding_boxes;
		setTotalColonyCount(boundingBoxesRef.current.length);
		setSensitivity(85);
		renderCanvas();
	};

	return (
		<div>
			{!loading ? (
				<Box
					sx={{
						justifyContent: "center",
						alignItems: "center",
						borderRadius: 1,
						overflow: "hidden",
						position: "relative",
						maxHeight: "80vh",
						maxWidth: "100%",
					}}
				>
					{editImageSeverity && editImageMessage && (
						<Alert severity={editImageSeverity} sx={{ marginBottom: 2 }}>
							{editImageMessage}
						</Alert>
					)}
					{showHelperMessage && (
						<Typography variant="body2" sx={{ marginBottom: 2 }}>
							{t("bboxEditor.instructions")}
						</Typography>
					)}
					<Grid container spacing={2}>
						<Grid
							item
							xs={12}
							md={8}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<TransformWrapper
								centerOnInit={true}
								doubleClick={{ disabled: true }}
								wheel={{ step: 0.1 }}
								onZoomChange={(e) => setZoom(e.state.scale)}
							>
								{({ zoomIn, zoomOut }) => (
									<>
										<Box
											sx={{
												borderRadius: 1,
												overflow: "hidden",
												position: "relative",
												maxHeight: "80vh", // Limit the maximum height
												maxWidth: "100%",
												alignItems: "center",
												alignContent: "center",
											}}
										>
											<TransformComponent>
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														flexDirection: "column",
														alignItems: "center",
														maxHeight: "80vh",
														overflow: "none",
													}}
												>
													<canvas
														ref={canvasRef}
														onClick={handleCanvasClick}
														style={{
															cursor: "pointer",
															maxWidth: "100%",
															maxHeight: "70vh",
														}}
													/>
												</Box>
											</TransformComponent>
											<Box sx={{ display: hideExtraControls ? "flex" : "none" }}>
												<Grid item xs={11}>
													<Typography variant="body1" gutterBottom>
														{t("bboxEditor.detectionSensitivity")}
													</Typography>
													<Slider
														value={sensitivity}
														onChange={(e, value) => handleSensitiveChange(value)}
														min={0}
														max={100}
														valueLabelDisplay="auto"
													/>
												</Grid>
											</Box>
											<Box
												sx={{
													position: "absolute",
													top: 8,
													left: 8,
													backgroundColor: "rgba(255, 255, 255, 0.7)",
													borderRadius: 1,
													display: "flex",
													flexDirection: "column",
												}}
											>
												<IconButton size="small" onClick={zoomIn}>
													<ZoomInRoundedIcon />
												</IconButton>
												<IconButton size="small" onClick={zoomOut}>
													<ZoomOutRoundedIcon />
												</IconButton>
											</Box>
										</Box>
									</>
								)}
							</TransformWrapper>
						</Grid>
						<Grid item xs={12} md={4} sx={{ display: hideExtraControls ? "none" : "block" }}>
							{/* Controls */}
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="body1" gutterBottom>
										{t("bboxEditor.detectionSensitivity")}
									</Typography>
									<Slider
										value={sensitivity}
										onChange={(e, value) => handleSensitiveChange(value)}
										min={0}
										max={100}
										valueLabelDisplay="auto"
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1" gutterBottom>
										{t("bboxEditor.bboxSize")}
									</Typography>
									<Slider
										value={boundingBoxSize}
										onChange={(e, value) => setBoundingBoxSize(value)}
										min={1}
										max={100}
										valueLabelDisplay="auto"
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth variant="standard">
										<InputLabel>
											{t("bboxEditor.cellType")}
										</InputLabel>
										<Select
											value={cellTypeID}
											onChange={(e) => setCellTypeID(e.target.value)}
										>
											{cellTypes.map((cellType, index) => (
												<MenuItem key={index} value={index}>
													{cellType}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth variant="standard">
										<InputLabel>
											{t("bboxEditor.shape")}
										</InputLabel>
										<Select
											value={isCircles ? "circle" : "rectangle"}
											onChange={(e) =>
												setIsCircles(e.target.value === "circle")
											}
										>
											<MenuItem value="rectangle">
												{t("bboxEditor.rectangle")}
											</MenuItem>
											<MenuItem value="circle">
												{t("bboxEditor.circle")}
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth variant="standard">
										<InputLabel>
											{t("bboxEditor.thickness")}
										</InputLabel>
										<Select
											value={thickness}
											onChange={(e) => setThickness(e.target.value)}
										>
											<MenuItem value={1}>
												{t("bboxEditor.small")}
											</MenuItem>
											<MenuItem value={5}>
												{t("bboxEditor.medium")}
											</MenuItem>
											<MenuItem value={10}>
												{t("bboxEditor.large")}
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1">
										{t("bboxEditor.colonyCount")}: {totalColonyCount}
									</Typography>
									{imageData.assay_type === "GFP/OFP" && (
										<Typography variant="body1">
											{t("bboxEditor.ratio")}: {ratio}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										{saveButton && (
											<Grid item>
												<ColorButton
													variant="colorButton"
													onClick={SaveChangesOnImage}
												>
													{t("bboxEditor.save")}
												</ColorButton>
											</Grid>
										)}
										{setOpenImageEditor && !saveButton && (
											<Grid item>
												<ColorButton
													variant="colorButton"
													onClick={() => setOpenImageEditor(false)}
												>
													{t("bboxEditor.close")}
												</ColorButton>
											</Grid>
										)}
										<Grid item>
											<ColorButton
												variant="colorButton"
												onClick={undoChanges}
											>
												{t("bboxEditor.reset")}
											</ColorButton>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{(imageData.assay_type === "Smear" ||
						imageData.assay_type === "GFP/OFP" ||
						imageData.assay_type === "Smear WBC") && (
							<Box sx={{ marginTop: 2, height: "200px", overflow: "auto" }}>
								<Table>
									<TableHead>
										<TableRow>
											{Object.keys(
												imageData.assay_type === "Smear"
													? BloodSmearRBCTypes
													: imageData.assay_type === "Smear WBC"
														? BloodSmearWBCTypes
														: imageData.assay_type === "GFP/OFP"
															? GFP_OFP_Classes
															: Assay7Classes
											).map((key, index) => {
												const count = boundingBoxesRef.current.filter(
													(box) =>
														box[4] === parseInt(key) &&
														((box[5] - 0.2) / 0.8) * 100 < sensitivity
												).length;
												return (
													<TableCell key={index}>
														({key}) {GFP_OFP_Classes[key]}
													</TableCell>
												);
											})}
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											{Object.keys(
												imageData.assay_type === "Smear"
													? BloodSmearRBCTypes
													: imageData.assay_type === "Smear WBC"
														? BloodSmearWBCTypes
														: imageData.assay_type === "GFP/OFP"
															? GFP_OFP_Classes
															: Assay7Classes
											).map((key, index) => {
												const count = boundingBoxesRef.current.filter(
													(box) =>
														box[4] === parseInt(key) &&
														((box[5] - 0.2) / 0.8) * 100 < sensitivity
												).length;
												return (
													<TableCell key={key}>{count}</TableCell>
												);
											})}
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						)}
				</Box>
			) : (
				<Box display="flex" justifyContent="center" mt={4}>
					<CircularProgress />
				</Box>
			)}
		</div>
	);
};

export default BBoxEditor;
