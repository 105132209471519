import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Alert, CircularProgress, Button } from '@mui/material';
import './SelectProject.css';
import { GetProjects } from '../../../services/project.service';
import CreateProjectFlow from '../../CreateProject/CreateProjectFlow';
import CreateTrialFlow from '../../CreateTrial/CreateTrialFlow';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { ColorButton, theme } from '../../../theme';

const SelectProject = ({ trialID, setTrialID, accessToken, userID, setOpenImageUploadModal, setSelectedProject, projects, setProjects, IDofSetProject }) => {
    const { t } = useTranslation();
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [trials, setTrials] = useState([]);
    const [trialCreated, setTrialCreated] = useState(false);
    const [projectID, setProjectID] = useState(null);
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [OpenCreateTrialFlow, setOpenCreateTrialFlow] = useState(false);
    const [createdTrialID, setCreatedTrialID] = useState(null);

    const showPickProjectPrompt = () => {
        setSeverity('error');
        setMessage(t('selectImages.selectProject.errors.selectProjectFirst'));
        setTimeout(() => {
            setSeverity('');
            setMessage('');
        }, 3000);
    };

    const onContinue = () => {
        setLoading(true);
        if (projectID === null || projectID === '') {
            setLoading(false);
            setSeverity('error');
            setMessage(t('selectImages.selectProject.errors.selectProject'));
            setTimeout(() => {
                setSeverity(null);
                setMessage(null);
            }, 3000);
            return;
        }
        if (trialID === null || trialID === '') {
            setLoading(false);
            setSeverity('error');
            setMessage(t('selectImages.selectProject.errors.selectTrial'));
            setTimeout(() => {
                setSeverity(null);
                setMessage(null);
            }, 3000);
            return;
        }

        setSelectedProject(projectID);
        setLoading(false);
        setOpenImageUploadModal();
    };

    useEffect(() => {
        if (IDofSetProject) {
            setProjectID(IDofSetProject);
        }
    }, [IDofSetProject]);

    useEffect(() => {
        updateTrials();
    }, [projectID, projects, setOpenCreateProject, OpenCreateTrialFlow]);

    useEffect(() => {
        GetProjects(accessToken, userID).then((response) => {
            setProjects(response.data);
            if (trialCreated && createdTrialID) {
                updateTrials();
                setTrialID(createdTrialID);
                setCreatedTrialID(null);
                setTrialCreated(false);
            }
        });
    }, [openCreateProject === false, OpenCreateTrialFlow === false]);

    const handleProjectDropdownChange = (event) => {
        setProjectID(event.target.value);
        setTrialID(null);
    };

    const handleTrialDropdownChange = (event) => {
        setTrialID(event.target.value);
    };

    const updateTrials = () => {
        if (projectID === null) {
            return;
        }
        for (let i = 0; i < projects.length; i++) {
            if (projects[i]._id === projectID) {
                setTrials(projects[i].project_trials);
                break;
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="select-project-container">
                <div className="form-title">
                    {t('selectImages.selectProject.title')}
                </div>
                <FormControl variant="outlined" className="dropdown">
                    <InputLabel required>{t('selectImages.selectProject.project')}</InputLabel>
                    <Select
                        value={projectID ? projectID : ''}
                        label={t('selectImages.selectProject.project')}
                        onChange={handleProjectDropdownChange}
                        options={projects}
                    >
                        <div className="add-button">
                            <Button variant='text' onClick={() => setOpenCreateProject(true)}>
                                {t('selectImages.selectProject.addProject')}
                            </Button>
                        </div>
                        {projects && projects.map((project) => (
                            <MenuItem value={project._id} key={project._id}>{project.project_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant='outlined' disabled={projectID ? false : true} onMouseDown={projectID ? null : showPickProjectPrompt} className="dropdown">
                    <InputLabel id="trial-label" required>{t('selectImages.selectProject.trial')}</InputLabel>
                    <Select labelId="trial-label" id="trial-select" value={trialID ? trialID : ''} onChange={handleTrialDropdownChange}>
                        {projectID && (
                            <div className="add-button">
                                <Button variant='text' onClick={() => setOpenCreateTrialFlow(true)}>
                                    {t('selectImages.selectProject.addTrial')}
                                </Button>
                            </div>
                        )}
                        {trials && trials.map((trial, index) => (
                            <MenuItem value={trial._id} key={trial._id}>{trial.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div>
                    {loading ? (
                        <CircularProgress color="inherit" />
                    ) : (
                        <div>
                            <ColorButton onClick={onContinue} variant="contained">
                                {t('selectImages.selectProject.next')}
                            </ColorButton>

                            {(severity === 'error' || severity === 'success') && (
                                <Alert severity={severity}>{message}</Alert>
                            )}
                        </div>
                    )}
                </div>
                {openCreateProject && (
                    <Modal
                        open={openCreateProject}
                        onClose={() => setOpenCreateProject(false)}
                    >
                        <CreateProjectFlow
                            setOpenCreateProject={setOpenCreateProject}
                            accessToken={accessToken}
                            userID={userID}
                            createTrialAfterProject={false}
                        />
                    </Modal>
                )}
                {OpenCreateTrialFlow && (
                    <Modal
                        open={OpenCreateTrialFlow}
                        onClose={() => setOpenCreateTrialFlow(false)}
                    >
                        <CreateTrialFlow
                            setOpenCreateTrialFlow={setOpenCreateTrialFlow}
                            selectedProjectID={projectID}
                            accessToken={accessToken}
                            userID={userID}
                            setOpenCreateProject={setOpenCreateProject}
                            refreshOnSubmit={false}
                            setTrialCreated={setTrialCreated}
                            setCreatedTrialID={setCreatedTrialID}
                        />
                    </Modal>
                )}
            </div>
        </ThemeProvider>
    );
};

export default SelectProject;