import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SelectProject from "./SelectProject/SelectProject";
import SelectImages from "./ImageUpload/SelectImages";
import ReviewImages from "./Review/ReviewImages";
import ResultsConfirmation from "./Results/ResultsConfirmation";
import { UploadFile } from "../../services/upload.service";
import { GetProjects } from "../../services/project.service";
import { theme } from "../../theme";

const ImageUploadModal = ({
  accessToken,
  userID,
  nameOfUser,
  projectID,
  setOpenConfirmationModal,
}) => {
  const { t } = useTranslation();
  const muiTheme = useTheme();

  const [currentStep, setCurrentStep] = useState("selectProject");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedTrial, setSelectedTrial] = useState(null);
  const [trialID, setTrialID] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [volumePlated, setVolumePlated] = useState(null);
  const [volumeUnits, setVolumeUnits] = useState("ml");
  const [dilutionFactor, setDilutionFactor] = useState(null);
  const [assayType, setAssayType] = useState("");
  const [boundingBoxesList, setBoundingBoxesList] = useState([]);
  const [detectionCountList, setDetectionCountList] = useState([]);
  const [notes, setNotes] = useState([]);
  const [sensitivity, setSensitivity] = useState([]);
  const [imagesProcessed, setImagesProcessed] = useState(0);
  const [trialRunUploaded, setTrialRunUploaded] = useState(false);
  const [adjustedBoundingBoxes, setAdjustedBoundingBoxes] = useState([]);
  const [newCounts, setNewCounts] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [message, setMessage] = useState("");

  // e gel data
  const [bandMeasurements, setBandMeasurements] = useState(null);
  const [class0MeasurementsInClass4, setClass0MeasurementsInClass4] = useState();
  const [selectedTemplateLadder, setSelectedTemplateLadder] = useState(null);
  const [selectedWells, setSelectedWells] = useState([0]);
  const [BPValueToFind, setBPValueToFind] = useState(null);
  const [BPMarginOfError, setBPMarginOfError] = useState(null);

  useEffect(() => {
    GetProjects(accessToken, userID).then((response) => {
      setProjects(response.data);
    });
  }, [accessToken, userID]);

  const ProcessImages = async () => {
    setCurrentStep("processingImages");
    let project = projects.find((project) => project._id === selectedProject);
    let trial = project
      ? project.project_trials.find((trial) => trial._id === trialID)
      : null;
    setSelectedTrial(trial);
    setSensitivity(new Array(fileList.length).fill(85));

    const newBoundingBoxesList = [];
    const newDetectionCountList = [];

    for (let i = 0; i < fileList.length; i++) {
      let formData = new FormData();
      formData.append("model_name", assayType);
      formData.append("Thres", 0);
      formData.append("id", userID);
      formData.append(fileNames[i], fileList[i]);

      try {
        const { data } = await UploadFile(accessToken, formData);
        let boundingBoxes = data[0].detection_info;
        newBoundingBoxesList.push(boundingBoxes);

        let detection_count = data[0].detections_count;
        newDetectionCountList.push(detection_count);

        setImagesProcessed(i + 1);
      } catch (error) {
        if (error.message === "permissions") {
          setCurrentStep("selectImages");
          setSeverity("error");
          setMessage(t("imageUploadModal.limitReached"));
          setTimeout(() => {
            setSeverity(null);
            setMessage("");
          }, 3000);
          return;
        }
      }
    }

    setBoundingBoxesList(newBoundingBoxesList);
    setAdjustedBoundingBoxes(newBoundingBoxesList);
    setDetectionCountList(newDetectionCountList);
    setBandMeasurements(new Array(fileList.length).fill([]));
    setClass0MeasurementsInClass4(new Array(fileList.length).fill([]));
    setNotes(new Array(fileList.length).fill(""));
    setNewCounts(newBoundingBoxesList.map((boundingBox) => boundingBox.length));
    setCurrentStep("reviewImages");
  };

  const handleClose = () => {
    setOpenConfirmationModal(true);
  };

  const renderContent = () => {
    switch (currentStep) {
      case "selectProject":
        return (
          <SelectProject
            accessToken={accessToken}
            userID={userID}
            setOpenImageUploadModal={() => setCurrentStep("selectImages")}
            setSelectedProject={setSelectedProject}
            setSelectedTrial={setSelectedTrial}
            selectedTrial={selectedTrial}
            projects={projects}
            setProjects={setProjects}
            IDofSetProject={projectID}
            setTrialID={setTrialID}
            trialID={trialID}
          />
        );
      case "selectImages":
        return (
          <SelectImages
            ProcessImages={ProcessImages}
            setDilutionFactor={setDilutionFactor}
            setVolumePlated={setVolumePlated}
            setVolumeUnits={setVolumeUnits}
            setFilenames={setFileNames}
            setFileList={setFileList}
            setAssayType={setAssayType}
            fileList={fileList}
            fileNames={fileNames}
            dilutionFactor={dilutionFactor}
            volumeUnits={volumeUnits}
            assayType={assayType}
            setSeverity={setSeverity}
            severity={severity}
            message={message}
            setMessage={setMessage}
            selectedTemplateLadder={selectedTemplateLadder}
            setSelectedTemplateLadder={setSelectedTemplateLadder}
            setBandMeasurements={setBandMeasurements}
            BPValueToFind={BPValueToFind}
            setBPValueToFind={setBPValueToFind}
            BPMarginOfError={BPMarginOfError}
            setBPMarginOfError={setBPMarginOfError}
          />
        );
      case "processingImages":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              padding: 2,
            }}
          >
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>
              {t("selectImages.imageUploadModal.processingImagesText")}
            </Typography>
          </Box>
        );
      case "reviewImages":
        return (
          <ReviewImages
            setAssayType={setAssayType}
            setDilutionFactor={setDilutionFactor}
            project={selectedProject}
            assayType={assayType}
            dilutionFactor={dilutionFactor}
            fileList={fileList}
            fileNames={fileNames}
            boundingBoxesList={boundingBoxesList}
            setConfirmResults={() => setCurrentStep("confirmResults")}
            setSensitivity={setSensitivity}
            sensitivity={sensitivity}
            notes={notes}
            setNotes={setNotes}
            newCounts={newCounts}
            setNewCounts={setNewCounts}
            imagesProcessed={imagesProcessed}
            adjustedBoundingBoxes={adjustedBoundingBoxes}
            setAdjustedBoundingBoxes={setAdjustedBoundingBoxes}
            setfileNames={setFileNames}
            // e gel data
            BPValueToFind={BPValueToFind}
            setBPValueToFind={setBPValueToFind}
            BPMarginOfError={BPMarginOfError}
            setBPMarginOfError={setBPMarginOfError}
            bandMeasurements={bandMeasurements}
            setBandMeasurements={setBandMeasurements}
            setClass0MeasurementsInClass4={setClass0MeasurementsInClass4}
            class0MeasurementsInClass4={class0MeasurementsInClass4}
            setSelectedTemplateLadder={setSelectedTemplateLadder}
            selectedTemplateLadder={selectedTemplateLadder}
            selectedWells={selectedWells}
            setSelectedWells={setSelectedWells}
          />
        );
      case "confirmResults":
        return (
          <ResultsConfirmation
            trialID={selectedTrial._id}
            trialName={selectedTrial.name}
            assayType={assayType}
            volumePlated={volumePlated}
            volumeUnits={volumeUnits}
            dilutionFactor={dilutionFactor}
            fileList={fileList}
            fileNames={fileNames}
            boundingBoxesList={boundingBoxesList}
            sensitivity={sensitivity}
            setConfirmResults={setTrialRunUploaded}
            setReviewImagesModal={() => setCurrentStep("reviewImages")}
            nameOfUser={nameOfUser}
            projectID={selectedProject}
            accessToken={accessToken}
            trialStartDate={selectedTrial.start_date}
            trialEndDate={selectedTrial.end_date}
            imageNotes={notes}
            newCounts={newCounts}
            setNewCounts={setNewCounts}
            setTrialRunUploaded={setTrialRunUploaded}
            trialRunUploaded={trialRunUploaded}
            adjustedBoundingBoxes={adjustedBoundingBoxes}
            // e gel data
            bandMeasurements={bandMeasurements}
            setBandMeasurements={setBandMeasurements}
            setClass0MeasurementsInClass4={setClass0MeasurementsInClass4}
            class0MeasurementsInClass4={class0MeasurementsInClass4}
            selectedTemplateLadder={selectedTemplateLadder}
            selectedWells={selectedWells}
            BPValueToFind={BPValueToFind}
            BPMarginOfError={BPMarginOfError}
          />
        );
      default:
        return null;
    }
  };

  // Determine the dialog title based on the current step
  const getDialogTitle = () => {
    switch (currentStep) {
      case "selectImages":
        return `${
          projects.find((p) => p._id === selectedProject)?.project_name || ""
        } - ${selectedTrial?.name || ""}`;
      case "processingImages":
        return t("selectImages.imageUploadModal.processingImages");
      case "reviewImages":
        return `${assayType} ${t(
          "selectImages.imageUploadModal.reviewImages"
        )} - ${selectedTrial?.name || ""}`;
      case "confirmResults":
        return `${
          projects.find((p) => p._id === selectedProject)?.project_name || ""
        } - ${selectedTrial?.name || ""}`;
      default:
        return t("selectImages.imageUploadModal.title");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true} // Set open to true to always display the modal
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        scroll="paper"
        aria-labelledby="image-upload-modal-title"
      >
        <DialogTitle
          id="image-upload-modal-title"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{getDialogTitle()}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{renderContent()}</DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ImageUploadModal;
