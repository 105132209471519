import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Alert,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { CreateProject, SendInvite } from "../../services/project.service";
import { GetUserHasPremium } from "../../services/user.service";
import CreateTrialFlow from "../CreateTrial/CreateTrialFlow";
import { ColorButton, theme } from "../../theme";
import MultiEmailField from '../../components/MultiEmailField/MultiEmailField';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export default function CreateProjectModal({
  setOpenCreateProject,
  accessToken,
  userID,
  createTrialAfterProject,
  projects,
  setProjects,
}) {
  const { t } = useTranslation();
  const muiTheme = useTheme();
  const [projectName, setProjectName] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedProjectID, setSelectedProjectID] = useState('');
  const [openCreateTrialScreen, setOpenCreateTrialScreen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [collaborators, setCollaborators] = useState([]);

  const navigate = useNavigate();

  const handleValidation = async () => {
    if (projectName === '' || selectedColor === '') {
      setSeverity('error');
      setMessage(t('createProject.requiredFields'));
      setTimeout(() => {
        setSeverity('');
        setMessage('');
      }, 3000);
      return false;
    }

    if (collaborators.length !== 0) {
      const response = await GetUserHasPremium(accessToken, userID);
      if (response.error || !response.data) {
        setSeverity('error');
        setMessage(t('createProject.permissionsError'));
        setTimeout(() => {
          setSeverity('');
          setMessage('');
        }, 3000);
        return false;
      }

      const emailRegex = /\S+@\S+\.\S+/;
      for (let i = 0; i < collaborators.length; i++) {
        if (!emailRegex.test(collaborators[i])) {
          setSeverity('error');
          setMessage(t('createProject.invalidEmail'));
          setTimeout(() => {
            setSeverity('');
            setMessage('');
          }, 3000);
          return false;
        }
      }

      if (collaborators.includes(userID)) {
        setSeverity('error');
        setMessage(t('createProject.selfInvite'));
        setTimeout(() => {
          setSeverity('');
          setMessage('');
        }, 3000);
        return false;
      }
    }

    return true;
  };

  const onContinue = async () => {
    if (loading) return;
    setLoading(true);
    if (!(await handleValidation())) {
      setLoading(false);
      return;
    }

    const project = {
      project_name: projectName,
      project_color: selectedColor,
      project_creator: userID,
      project_description: description,
      project_members: [userID],
    };

    const formData = new FormData();
    formData.append('project', JSON.stringify(project));

    try {
      const response = await CreateProject(accessToken, formData);
      if (response.error || !response.data || !response.data._id) {
        throw new Error(response.error || t('createProject.createError'));
      } else {
        let error = 0;
        let projectID = response.data._id;
        setSelectedProjectID(response.data._id);
        if (setProjects) {
          const newProject = {
            _id: projectID,
            project_name: projectName,
            project_color: selectedColor,
            project_description: description,
            project_creator: userID,
            project_members: [userID],
          };
          setProjects((prevProjects) => [...prevProjects, newProject]);
        }
        if (collaborators.length > 0) {
          for (const collaborator of collaborators) {
            const inviteFormData = new FormData();
            inviteFormData.append('email', collaborator);
            inviteFormData.append('user_id', userID);
            inviteFormData.append('project_id', response.data._id);
            inviteFormData.append('project_name', projectName);

            const inviteResponse = await SendInvite(accessToken, inviteFormData);
            if (inviteResponse.error || inviteResponse.data?.error) {
              error = 1;
              throw new Error(
                inviteResponse.error?.message ||
                  inviteResponse.data?.error ||
                  t('createProject.inviteError')
              );
            }
          }
        }
        if (error === 0) {
          navigate('/project/' + projectID);
          setOpenCreateProject(false);
        }
      }
    } catch (error) {
      setSeverity('error');
      setMessage(error.message || t('createProject.generalError'));
      setLoading(false);
      return;
    }

    setLoading(false);
    setTimeout(() => {
      setSeverity('');
      setMessage('');
    }, 3000);
  };

  const handleColorChange = (newColor) => {
    setSelectedColor(newColor);
  };

  const handleClose = () => {
    if (setOpenCreateProject) {
      setOpenCreateProject(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {openCreateTrialScreen ? (
        <CreateTrialFlow
          selectedProjectID={selectedProjectID}
          accessToken={accessToken}
          userID={userID}
          setOpenCreateProject={setOpenCreateProject}
          refreshOnSubmit={true}
          setOpenCreateTrialFlow={setOpenCreateTrialScreen}
        />
      ) : (
        <Dialog
          open={true}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          scroll="paper"
          aria-labelledby="create-project-modal-title"
        >
          <DialogTitle
            id="create-project-modal-title"
            sx={{
              bgcolor: muiTheme.palette.primary.main,
              color: muiTheme.palette.primary.contrastText,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{t('createProject.title')}</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ color: muiTheme.palette.primary.contrastText }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('createProject.subtitle')}
              </Typography>
              <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={t('createProject.projectName')}
                      variant="outlined"
                      fullWidth
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MultiEmailField emails={collaborators} setEmails={setCollaborators} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {t('createProject.selectColor')} *
                    </Typography>
                    <FormGroup row>
                      {['#FC5A5A', '#FFA043', '#7FF6BC', '#83B4FF', '#D3BCFC', '#FDAEFF', '#84818A'].map((color) => (
                        <FormControlLabel
                          key={color}
                          value={color}
                          onClick={() => handleColorChange(color)}
                          control={
                            <Box
                              sx={{
                                backgroundColor: color,
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                border: selectedColor === color ? '2px solid #23C9E8' : '1px solid #ccc',
                                cursor: 'pointer',
                              }}
                            />
                          }
                          label=""
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('createProject.description')}
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Grid>
                  {severity && (
                    <Grid item xs={12}>
                      <Alert severity={severity}>{message}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {loading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <ColorButton variant="contained" onClick={onContinue}>
                        {t('createProject.continue')}
                      </ColorButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </ThemeProvider>
  );
}
