import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GetProjects, CreateTrial } from "../../services/project.service";
import { ColorButton, theme } from "../../theme";
import { useTranslation } from 'react-i18next';

export default function CreateTrialModal({
  setOpenCreateTrialFlow,
  selectedProjectID,
  accessToken,
  userID,
  refreshOnSubmit,
  setTrialCreated,
  setCreatedTrialID
}) {
  const { t } = useTranslation();
  const muiTheme = useTheme();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [trialName, setTrialName] = useState('');
  const [notes, setNotes] = useState('');
  const [projectID, setProjectID] = useState(selectedProjectID);
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

  const onCreateTrialFormSubmit = () => {
    setCreateButtonDisabled(true);
    setLoading(true);

    let errors = false;
    if (startDate === null || projectID === '' || trialName === '') {
      errors = true;
      setSeverity('error');
      setMessage(t('createTrial.requiredFields'));
      setLoading(false);
    }
    if (!errors && endDate != null && endDate < startDate) {
      errors = true;
      setSeverity('error');
      setMessage(t('createTrial.endDateError'));
      setLoading(false);
    }

    if (!errors) {
      // Call API to create trial
      let formData = new FormData();
      formData.append('project_id', projectID);
      formData.append('start_date', startDate);
      if (endDate) {
        formData.append('end_date', endDate);
      }
      formData.append('name', trialName);
      formData.append('trial_notes', notes);
      formData.append('trial_creator', userID);

      setLoading(true);
      CreateTrial(accessToken, formData).then((response) => {
        if (response.error) {
          setSeverity('error');
          setMessage(t('createTrial.createError'));

          setTimeout(() => {
            setSeverity('');
            setMessage('');
            setLoading(false);
          }, 3000);
        } else {
          setMessage(t('createTrial.createSuccess'));
          setSeverity('success');
          if (setCreatedTrialID !== undefined) {
            setCreatedTrialID(response.data._id);
          }
          setTimeout(() => {
            setSeverity('');
            setMessage('');
            setLoading(false);
            if (refreshOnSubmit) {
              window.location.reload();
            } else {
              if (setTrialCreated !== undefined) {
                setTrialCreated(true);
              }
              if (setOpenCreateTrialFlow !== undefined) {
                setOpenCreateTrialFlow(false);
              }
            }
          }, 500);
        }
      });
      setLoading(false);
    } else {
      setCreateButtonDisabled(false);
      setTimeout(() => {
        setSeverity('');
        setMessage('');
        setLoading(false);
      }, 3000);
    }
  };

  const handleDropdownValueChange = (event) => {
    setProjectID(event.target.value);
  };

  useEffect(() => {
    GetProjects(accessToken, userID).then((response) => {
      setProjects(response.data);
    });
  }, [accessToken, userID]);

  const handleClose = () => {
    if (setOpenCreateTrialFlow !== undefined) {
      setOpenCreateTrialFlow(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
        aria-labelledby="create-trial-modal-title"
      >
        <DialogTitle
          id="create-trial-modal-title"
          sx={{
            bgcolor: muiTheme.palette.primary.main,
            color: muiTheme.palette.primary.contrastText,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{t('createTrial.title')}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: muiTheme.palette.primary.contrastText }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t('createTrial.subtitle')}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="project-select-label">{t('createTrial.project')}*</InputLabel>
                      <Select
                        labelId="project-select-label"
                        id="project-select"
                        value={projectID ? projectID : ''}
                        label={`${t('createTrial.project')}*`}
                        onChange={handleDropdownValueChange}
                      >
                        {projects && projects.map((project) => (
                          <MenuItem value={project._id} key={project._id}>{project.project_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label={`${t('createTrial.startDate')}*`}
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label={t('createTrial.endDate')}
                      value={endDate}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={`${t('createTrial.trialName')}*`}
                      variant="outlined"
                      fullWidth
                      value={trialName}
                      onChange={(e) => setTrialName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('createTrial.notes')}
                      variant="outlined"
                      fullWidth
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  {severity && message && (
                    <Grid item xs={12}>
                      <Alert severity={severity}>{message}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {createButtonDisabled ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <ColorButton
                        variant="contained"
                        onClick={onCreateTrialFormSubmit}
                        disabled={createButtonDisabled}
                      >
                        {t('createTrial.create')}
                      </ColorButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

